import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

import TypeScriptImg from '../assets/typescript.png';
import AWSImg from '../assets/aws-2.png';
import ReactImg from '../assets/react-2.png';
import PythonImg from '../assets/python-2.png';
import NodeJSImg from '../assets/nodejs-2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  skillImg: {
    width: '8.26vw',
    marginBottom: '-0.4vw',
    fontSize: 0,
  },
  skillImgXs: {
    width: '12.5vw',
    marginBottom: '-1.7vw',
    fontSize: 0,
  },
  overlay: {
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  name: {
    color: '#ffffff',
    fontSize: '42pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    [theme.breakpoints.down('md')]: {
      fontSize: '20pt',
    },
  },
  position: {
    color: '#ffffff',
    fontSize: '30pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    [theme.breakpoints.down('md')]: {
      fontSize: '16pt',
    },
  },
  location: {
    color: '#ffffff',
    fontSize: '20pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    [theme.breakpoints.down('md')]: {
      fontSize: '14pt',
    },
  }
}));

const Header = () => {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Hidden lgUp>
          <img src={TypeScriptImg} alt="Type Script" className={classes.skillImgXs} />
          <img src={AWSImg} alt="AWS" className={classes.skillImgXs} />
          <img src={ReactImg} alt="AWS" className={classes.skillImgXs} />
          <img src={PythonImg} alt="AWS" className={classes.skillImgXs} />
          <img src={NodeJSImg} alt="AWS" className={classes.skillImgXs} />
        </Hidden>
        <Hidden mdDown>
          <img src={TypeScriptImg} alt="Type Script" className={classes.skillImg} />
          <img src={AWSImg} alt="AWS" className={classes.skillImg} />
          <img src={ReactImg} alt="AWS" className={classes.skillImg} />
          <img src={PythonImg} alt="AWS" className={classes.skillImg} />
          <img src={NodeJSImg} alt="AWS" className={classes.skillImg} />
        </Hidden>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div id="home" style={{ height: '100vh', overflow: 'hidden' }}>
        {[...Array(80).keys()].map(
          (i) => <FormRow key={i} />
        )}
      </div>
      <div className={classes.overlay}>
        <div style={{
          width: '100%',
          height: '100vh',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
          <Grid container item xs={12} lg={6}
            style={{
              borderColor: 'white',
              borderWidth: 2,
              borderStyle: 'solid',
              paddingTop: '1vw',
              paddingBottom: '1vw',
              backgroundColor: 'rgba(0, 0, 0, 0.55)',
            }}
          >
            <Grid item className={classes.name} xs={12}>
              TIMOTHY GAUL
            </Grid>
            <Grid item className={classes.position} xs={12}>
              DevOps &amp; Cloud Engineer
            </Grid>
            <Grid item className={classes.location} xs={12}>
              Brisbane, Queensland
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;